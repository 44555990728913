"use strict";

import "regulus-oscar";
import "./components/amex/featured-links";
import "./components/amex/product-list";
import "./components/amex/vehicle-form";


// Disable the dropdown-toggle on category menu items on large screens.
$('.header-menu .nav-link').on('click', function(e) {
    if ($(window).width() >= 992) {
        e.stopPropagation();
    }
});
