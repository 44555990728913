"use strict";

import "slick-carousel";

$(function(){
    $('.featured-links').slick({
        prevArrow: ".featured-links__prev-arrow",
        nextArrow: ".featured-links__next-arrow",
        slidesPerRow: 4,
        infinite: false,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesPerRow: 2,
                    centerMode: true,
                    centerPadding: '10%',
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesPerRow: 1,
                    centerMode: true,
                    centerPadding: '10%',
                }
            }
        ]
    });
});
